import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Saved.css';
import screen1 from '../../img/saved/1.jpg'
import screen2 from '../../img/saved/2.jpg'
import screen3 from '../../img/saved/3.jpg'

export default class Saved extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: [
        {
          screen: screen1,
        },
        {
          screen: screen2,
        },
        {
          screen: screen3,
        },
      ]
    }

    this.changeStatusString = this.changeStatusString.bind(this);
  }

  renderSaved(item, i) {
    return (
      <div className="saved_item" key={`team-${i}`}>
        <img className="saved_photo" src={item.screen} alt='' />
      </div>
    )
  }

  changeStatusString() {
    setTimeout(() => {
      let replacedText = document.querySelector('.saved_slider--pc').querySelector('.carousel-status').innerHTML.replace(" of ", "/");
      let replacedTextMob = document.querySelector('.saved_slider--mob').querySelector('.carousel-status').innerHTML.replace(" of ", "/");
      document.querySelector('.saved_slider--pc').querySelector('.carousel-status').innerHTML = replacedText;
      document.querySelector('.saved_slider--mob').querySelector('.carousel-status').innerHTML = replacedTextMob;
    }, 0)
  }

  componentDidMount() {
    this.changeStatusString();
  }

  render() {
    return (
      <div className="saved_wrap" id='saved'>
        <div className='container saved'>
          <h2 className="saved_title">ІСТОРІЇ<br />ВРЯТОВАНИХ</h2>
          <div className='saved_slider saved_slider--pc'>
            <Carousel infiniteLoop selectedItem={1} onSwipeEnd={this.changeStatusString} onChange={this.changeStatusString} stopOnHover={true} showIndicators={false} showThumbs={false} autoFocus={true} autoPlay centerSlidePercentage={33.4} centerMode={true}>
              {this.state.saved.map((item, i) => this.renderSaved(item, i))}
            </Carousel>
          </div>
          <div className='saved_slider saved_slider--mob'>
            <Carousel infiniteLoop selectedItem={1} onSwipeEnd={this.changeStatusString} onChange={this.changeStatusString} stopOnHover={true} showIndicators={false} showThumbs={false} autoFocus={true} autoPlay centerSlidePercentage={60} centerMode={true}>
              {this.state.saved.map((item, i) => this.renderSaved(item, i))}
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}