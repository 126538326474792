import React from 'react';
import close from '../../img/close.svg'
import Form from './Form/Form'
import Popup from 'reactjs-popup';
import './Popup_sponsors.css';

export default class Popup_sponsors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vis: false,
        }

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
    }

    openPopup() {
        this.setState({ vis: true });
    }

    closePopup() {
        this.setState({ vis: false });
    }

    togglePopup() {
        this.setState({ vis: !this.state.vis });
    }


    render() {
        return (
            <>
                <Popup
                    trigger={<button className='button'>Врятувати життя</button>}
                    modal
                    nested
                    onOpen={() => { document.querySelector('html').style.setProperty('overflow', 'hidden'); document.querySelector('html').style.setProperty('position', 'relative'); document.querySelector('html').style.setProperty('height', '100%') }}
                    onClose={() => { document.querySelector('html').style.setProperty('overflow', 'auto') }}
                >
                    {close => (
                        <>
                            <div className='popup_container'>
                                <div className='popup_form'>
                                    <Form />
                                </div>
                            </div>
                            <img className='popup_close' src={close} onClick={this.togglePopup} alt="" />
                        </>
                    )}
                </Popup>
            </>
        )
    }
}