import React from 'react';
import './HowWorks.css';
import Evacuate_me from '../Evacuate_me/Evacuate_me'

const HowWorks = (props) => {
    const state = {
        HowWorks_items: [
            {
                id: 1,
                title: 'Спілкування',
                desc: 'Ми багато спілкуємось з родиною, щоб зроуміти всі деталі та нюанси конкретної ситуації.',
            },
            {
                id: 2,
                title: 'Планування',
                desc: 'Пропонуємо дати, маршрути, країни. Узгоджуємо все з нашими партнерами та записуємо на евакуацію, купуємо квитки, домовляємось про вивоз з окупованих територій, тощо...',
            },
            {
                id: 3,
                title: 'Подорож',
                desc: 'Супроводжуємо по телефону на кожному етапі, щоб бути впевненими, що все йде за планом та в разі його зміни корегувати всі наступні кроки.',
            },
        ],
    };

    const renderHowWorks_item = (item, i) => {
        return (
            <div className='howworks-item' key={`howworks-item-${i}`}>
                <div className='howworks-item_header'>
                    <span className='howworks-item_num'>{item.id}</span>
                    <p className='howworks-item_title'>{item.title}</p>
                </div>
                <p className='howworks-item_desc'>{item.desc}</p>
            </div>

        );
    };

    return (
        <div className="howworks-wrap" id='howworks'>
            <div className="container howworks">
                <h2 className='howworks-title'>ЯК ПРАЦЮЄ<br />місія</h2>
                <div className='howworks-items'>
                    {state.HowWorks_items.map((item, i) => renderHowWorks_item(item, i))}
                </div>
                <Evacuate_me />
            </div>
        </div>
    );
};

export default HowWorks;