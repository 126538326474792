import React from 'react';
import './FAQ.css';

export default class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FAQ: [
                {
                    title: `Це безкоштовно?`,
                    parags: [
                        `Так, евакуація безкоштовна.`,
                    ],
                },
                {
                    title: `Які документи потрібні?`,
                    parags: [
                        `Зараз можна перетинати кордон за українськими документами, потрібні закордонні паспорти, якщо ви проживаєте не на території бойових дій або окупованій території`,
                    ],
                },
                {
                    title: `В які країни евакуюють?`,
                    parags: [
                        `Зараз ми евакуюємо в Польшу та Німеччину, також Австрію.`,
                    ],
                },
                {
                    title: `Чи буде можливісь працювати?`,
                    parags: [
                        `Є можливість працювати, навіть без знання мови та оформити дітей до школи та дитячих садків.`,
                    ],
                },
                {
                    title: `Чи надає держава соціальну допомогу?`,
                    parags: [
                        `Країни, в які ми евакуюємо, надають соціальну допомогу.`,
                    ],
                },
                {
                    title: `Скільки часу займає евакуація?`,
                    parags: [
                        `В залежності у яку країну проходить евакуація (Польша - кожного дня, Німеччина - некілька разів на тиждень, Австрія - 1 раз на тиждень, якщо є автобус).`,
                    ],
                },
                {
                    title: `Чи надають місце проживання?`,
                    parags: [
                        `Спочатку вас привозять у волонтерський центр, а потім вже йде розселення у соціальне житло.`,
                    ],
                },
                {
                    title: `Які правила стосовно вакцінації? `,
                    parags: [
                        `Вакцинація не є примусовою і її відсутність не має ніякого значення.`,
                    ],
                },
            ],
        }
    }

    renderFAQ(item, i) {
        return (
            <details className='faq-item' key={`faq-item--${i}`}>
                <summary className='faq-item--title'>
                    {item.title}
                </summary>
                <ul className='faq-item--list'>
                    {item.parags.map((item, i) => <li className='description_item--list_item' key={`description_item_p--${i}`}>{item}</li>)}
                </ul>
            </details>
        )
    }

    render() {
        return (
            <div className="faq-wrap" id='faq'>
                <div className='container faq'>
                    <h2 className='faq-title'>популярні питання</h2>
                    <div className="faq-block">
                        {this.state.FAQ.map((item, i) => this.renderFAQ(item, i))}
                    </div>
                </div>
            </div>
        )
    }
}
