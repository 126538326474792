import React, { useState } from "react";
import { Link } from "react-scroll";
import OpenMenu from "./OpenMenu/OpenMenu.jsx";
import burgerMenu from '../../../img/burgerMenu.svg';
import './Menu.css';

const Menu = (props) => {
  const [showText, setShowText] = useState(false);
  const onClick = () => setShowText(!showText);

  return (
    <>
      {showText ? (
        <div className="mob_blur_bcg" onClick={onClick}></div>
      ) : null
      }
      <ul className='menu'>
        <li className='menu_pc menu_pc--without_ml'>
          <Link
            activeClass="active"
            to='about'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Про нас
          </Link>
        </li>
        <li className='menu_pc'>
          <Link
            activeClass="active"
            to='whoevacuate'
            spy={true}
            smooth={true}
            offset={80}
          >
            Місія
          </Link>
        </li>
        <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='howworks'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Як працює місія
          </Link>
        </li>
        <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='saved'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Відгуки
          </Link>
        </li>
        {/* <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='donate'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Донат
          </Link>
        </li> */}
        <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='faq'
            spy={true}
            smooth={true}
            offset={-94}
          >
            FAQ
          </Link>
        </li>
        <div className="mobile-menu">
          <div className="mobile-menu_img-block">
            <div className={`${showText === true && 'active'} menu_toggle_wrapper`} onClick={onClick}>
              <span className="menu_toggle"></span>
              <img className="burger-menu" src={burgerMenu} alt="" />
            </div>
          </div>
          <div>
            {showText ? (
              <React.Fragment>
                <OpenMenu closeFunc={onClick} />
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </ul>
    </>
  );
}

export default Menu;