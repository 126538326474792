import './main.css';
import { Link } from "react-scroll";
import React from 'react';
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstScreenHeight: document.documentElement.clientHeight,
        }

        this.changeScreenHeight = this.changeScreenHeight.bind(this);
    }

    changeScreenHeight() {
        this.setState({
            firstScreenHeight: document.documentElement.clientHeight
        });

        document.querySelector('.main_wrap').style.height = this.state.firstScreenHeight + 'px';
    }

    componentWillMount = () => {
        window.addEventListener("resize", this.changeScreenHeight);
        return () => window.removeEventListener("resize", this.changeScreenHeight);
    }

    componentDidMount() {
        this.changeScreenHeight();
    }

    render() {
        return (
            <div className='main_wrap'>
                <div className='container main_block'>
                    <h1 className='main_title'>НАША МІСІЯ -<br />врятувати сім'ї<br />з дітьми</h1>
                    <p className='main_undertitle'>Нам потрібна твоя допомога.</p>
                    <Popup_sponsors />
                </div>
            </div>
        )
    }
}