import React from 'react';
import './OurHistory.css';
import bg from '../../img/ourHistory/history-bg.jpg'

const OurHistory = () => {
    const state = {
        ourhistory: [
            {
                date: '25 Лютого 2022',
                desc: 'Ми почали працювати з біженцями на кордоні з Румунією',
            },
            {
                date: '01 Березня 2022',
                desc: 'Було прийнято рішення про створення фонду та створення перших системних процесів місії',
            },
            {
                date: 'Березень 2022',
                desc: 'Перші домовленності з готелями в Румунії. Організація трансферу з кордону до безпечних номерів, де українцям надають прихисток безкоштовно.',
            },
            {
                date: 'Квітень 2022',
                desc: 'Розуміємо, що Румунія вже переповнена нашими біженцями.Пошук партнерів в інших караїнах Європи, які б могли надавати допомогу і житло українцям.',
            },
            {
                date: 'Червень 2022',
                desc: `Маємо варіанти куди евакуювати сім'і практично по всій Європі. Розробляємо детальний план евакуації по кожній із країн.`,
            },
            {
                date: 'Літо 2022',
                desc: 'Вивозимо людей з небезпечних міст України на регулярній основі. Допомогаємо виїхати за кордон та отримати прихісток і допомогу.',
            },
            {
                desc: 'Маємо досвід вирішення багатьох питань і консультуємо біженців. Ділимося перевіренною інформацією та надаємо психологічну підтримку.',
            },
        ],
    };

    const renderOurhistory = (item, i) => {
        return (
            <div className="ourhistory-item" key={`numbers-item-${i}`}>
                {item.date && <p className='ourhistory-date'>{item.date}</p>}
                <p className='ourhistory-desc'>{item.desc}</p>
            </div>
        );
    };

    return (
        <div className="ourhistory-wrap" id='ourhistory'>
            <div className='container ourhistory'>
                <div className='ourhistory-outer'>
                    <div className='ourhistory-inner'>
                        <h2 className='ourhistory-title'>Наша історія</h2>
                        <div className='ourhistory-content'>
                            {state.ourhistory.map((item, i) => renderOurhistory(item, i))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurHistory;