import React from 'react';
import './Numbers.css';
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

const Numbers = () => {
    const state = {
        numbers: [
            {
                who: 'Дітей',
                number: '1700',
            },
            {
                who: 'Дорослих',
                number: '1300',
            },
            {
                who: 'Всього',
                number: '3000+',
            },
        ],
    };

    const renderNumbers = (item, i) => {
        return (
            <div className="numbers-item" key={`numbers-item-${i}`}>
                <p className='numbers-who'>{item.who}</p>
                <p className='numbers-number'>{item.number}</p>
            </div>
        );
    };

    return (
        <div className="numbers-wrap" id='numbers'>
            <div className='container numbers'>
                <h2 className='numbers-title'>Нам ВДАЛОСЬ<br />ВРЯТУВАТИ</h2>
                <div className='numbers-content'>
                    {state.numbers.map((item, i) => renderNumbers(item, i))}
                </div>
                <h3 className='numbers-title-h3 green'>МИ МОЖЕМО ВРЯТУВАТИ БІЛЬШЕ, ДОПОМОЖИ!</h3>
                <Popup_sponsors />
            </div>
        </div>
    );
};

export default Numbers;