import React from 'react';
import './EvacuateWhere.css';
import Evacuate_me from '../Evacuate_me/Evacuate_me'

const EvacuateWhere = () => {
    return (
        <div className="evacuatewhere-wrap" id='evacuatewhere'>
            <div className='container evacuatewhere'>
                <div className='evacuatewhere_inner'>
                    <h2 className='evacuatewhere-title'>КУДИ ми<br />ЕВАКУЮЄМО?</h2>
                    <p className='evacuatewhere-desc'>Ми допомагаємо у виїзді в ті країни Європи, де в нас є патрнерськи волонтерськи організації, які допомагають облаштуватися</p>
                    <h3 className='evacuatewhere-title-h3'>ми веземо вас у безпечне місце</h3>
                    <Evacuate_me />
                </div>
            </div>
        </div>
    );
};

export default EvacuateWhere;