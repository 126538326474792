import React from 'react';
import './EvacuateFrom.css';
import mapofUkraine from '../../img/EvacuateFrom/mapofUkraine.svg'
import Popup_sponsors from '../Popup_sponsors/Popup_sponsors'

const EvacuateFrom = () => {
    return (
        <div className="evacuatefrom-wrap" id='evacuatefrom'>
            <div className='container evacuatefrom'>
                <div className='evacuatefrom-content'>
                    <h2 className='evacuatefrom-title'>ЗВІДКИ<br />ЕВАКУЮЄМО?</h2>
                    <p className='evacuatefrom-desc'>Діти не мають залишатись в районах проведення бойових дій.</p>
                    <p className='evacuatefrom-desc'>Ми допомагаємо полишити місця де ведуться бої на окупованих територій України, особливо регіони зазначні найближчими до деокупації.</p>
                    <div className='evacuatefrom-img-block evacuatefrom-img-block_mob'>
                        <img className='evacuatefrom-img-mob' src={mapofUkraine} alt="" />
                    </div>
                    <Popup_sponsors />
                </div>
                <div className='evacuatefrom-img-block evacuatefrom-img-block_pc'>
                    <img className='evacuatefrom-img' src={mapofUkraine} alt="" />
                </div>
            </div>
        </div>
    );
};

export default EvacuateFrom;