import React from 'react';
import './WhoEvacuate.css';

const WhoEvacuate = () => {
    return (
        <div className="whoevacuate-wrap" id='whoevacuate'>
            <div className='container whoevacuate'>
                <div className='whoevacuate_inner'>
                    <h2 className="whoevacuate-title">КОГО ЕВАКУЮЄ</h2>
                    <h3 className='whoevacuate-title-h3'>evacuate.me</h3>
                    <div className="whoevacuate-content">
                        <p className="whoevacuate-content_title">Ми працюємо виключно з родинами, в яких є неповнолітні діти.</p>
                        <p className="whoevacuate-content_text">Так, ми співчуваємо кожному й мріємо допомагати всім бажаючим, але в умовах мізерного ресурсу ми вирішили концентруватися на майбутньому України - її дітях.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhoEvacuate;