import React from 'react';
import './About.css';
import post from '../../img/about/post.jpg'

const About = () => {
    return (
        <div className="about_wrap grey" id='about'>
            <div className='container about'>
                <div className='about-inner'>
                    <div className='about-content'>
                        <h2 className='about-title'>хто ми?</h2>
                        <div className='about-content_parag'>
                            <p className='about-content_parag-desc'>Ми волонтерська спільнота, яка має мету захистити якомога більше дітей і сімей які потребують допомоги в евакуації.</p>
                            <p className='about-content_parag-desc'>Ми евакуюємо людей з гарячих точок нашої країни, та надаємо психологічну і матеріальну підтримку.</p>
                        </div>
                        <div className='about-content_parag'>
                            <p className='about-content_parag-title'>З чого все почалось?</p>
                            <p className='about-content_parag-desc'>Ми опублікували пост в Facebook з закликом <span className='green'>“ВИВОЗЬТЕ ДІТЕЙ!”</span> з метою допомогти батькам усвідомити реальну загрозу життю їхніх дітей та прийняти рішення яке <span className='green'>врятує життя</span>.</p>
                        </div>
                        <h3 className='about-title-h3 about-title-h3_pc'>ми отримали тисячі повідомлень і <span className='green'>почали діяти</span></h3>
                    </div>
                    <div className='about-block-img'>
                        <img className='about-img' src={post} alt="" />
                    </div>
                </div>
                <h3 className='about-title-h3 about-title-h3_mob'>ми отримали тисячі повідомлень і <span className='green'>почали діяти</span></h3>
            </div>
        </div>
    );
};

export default About;