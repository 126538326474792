import React, { useState, useEffect } from 'react';
import './Form.css'
import { send } from 'emailjs-com';
import fail from '../../../img/fail.svg'
import success from '../../../img/success.svg'

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [linkError, setLinkError] = useState(true);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                default:
            }
        }
    }, [value, validations]);

    useEffect(() => {
        if (isEmpty) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }

    }, [isEmpty])

    return {
        isEmpty
    }
}

const useInput = (initialValue, validations, isSkills) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid
    }
}

const Form = (props) => {
    const name = useInput('', { isEmpty: true });
    const email = useInput('', { isEmpty: true });
    const contacts = useInput('', { isEmpty: true });
    const textArea = useInput('', { isEmpty: true });
    const [letterSucces, setLetterSucces] = useState('');
    const [textErr, setTextErr] = useState('');

    const data = {
        from_name: name.value,
        email: email.value,
        contacts: contacts.value,
        message: textArea.value,
        topic: 'Хочу стати спонсором',
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        send(
            'service_ey8qxzu',
            'template_s2jrjfl',
            data,
            '_etWiG8SHb-9w-1DV'
        )
            .then((response) => {
                setLetterSucces(true);
            })
            .catch((err) => {
                setLetterSucces(false);
                setTextErr(err);
            });
    }

    return (
        <>
            {!(letterSucces === true || letterSucces === false) && <>
                <h2 className='popup_title'>Задонатити</h2>
                <p className='popup_desc popup_desc--sponsor'>Будь яка сума допоможе врятувати дітей</p>
            </>}
            {(letterSucces === true || letterSucces === false) && <>
                <h2 className='popup_title'>Дякуємо вам, що відгукнулися!</h2>
            </>}
            {!(letterSucces === true || letterSucces === false) && <form className='popup_form' onSubmit={onSubmit}>
                <div className='popup_inputs_block'>
                    <label>
                        <input placeholder='ФІО' onChange={e => name.onChange(e)} onBlur={e => name.onBlur(e)} className='popup_input popup_name' value={name.value} type="text" name="from_name" />
                    </label>
                    <label>
                        <input placeholder='Пошта' onChange={e => email.onChange(e)} onBlur={e => email.onBlur(e)} className='popup_input popup_name' value={email.value} type="email" name="email" />
                    </label>
                    <label>
                        <input placeholder='Телефон (необовʼязково)' onChange={e => contacts.onChange(e)} onBlur={e => contacts.onBlur(e)} className='popup_input popup_name' value={contacts.value} type="text" name="contacts" />
                    </label>
                    <label>
                        <textarea placeholder='Комментар (необовʼязково)' onChange={e => textArea.onChange(e)} onBlur={e => textArea.onBlur(e)} className='popup_input popup_name' value={textArea.value} type="text" name="message" />
                    </label>
                </div>
                <input disabled={name.isEmpty || email.isEmpty} className='popup_btn form_btn button' type="submit" value="Відправити" />
            </form>}
            {
                letterSucces === true && <div className='form_message'>
                    <img className='form_message--img' src={success} alt="" />
                    <p>Лист надісланий, мы зв'яжемося з вами найближчим часом!</p>
                </div>
            }
            {
                letterSucces === false && <div className='form_message'>
                    <img className='form_message--img' src={fail} alt="" />
                    <p>Не вдалося доставити надісланий вами лист. Помилка: {textErr}</p>
                </div>
            }
        </>
    );
};

export default Form;