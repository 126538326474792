import React from "react";
import './App.css';
import Header from './components/Header/Header.jsx';
import Main from './components/main/main.jsx';
import Numbers from "./components/Numbers/Numbers";
import WhoEvacuate from "./components/WhoEvacuate/WhoEvacuate";
import HowWorks from './components/HowWorks/HowWorks.jsx';
import Saved from './components/Saved/Saved.jsx';
import Footer from './components/footer/Footer.jsx';
import About from './components/About/About';
import EvacuateFrom from './components/EvacuateFrom/EvacuateFrom';
import EvacuateWhere from './components/EvacuateWhere/EvacuateWhere';
import Donate from './components/Donate/Donate';
import OurHistory from './components/OurHistory/OurHistory';
import FAQ from './components/FAQ/FAQ';

const App = () => {
  return (
    <main>
      <Header />
      <Main />
      <About />
      <Numbers />
      <WhoEvacuate />
      <HowWorks />
      <EvacuateFrom />
      <EvacuateWhere />
      <Saved />
      {/* <Donate /> */}
      <OurHistory />
      <FAQ />
      <Footer />
    </main>
  );
}

export default App;