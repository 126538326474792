import React, { useState, useEffect } from 'react';
import './Form.css'
import { send } from 'emailjs-com';
import fail from '../../../img/fail.svg'
import success from '../../../img/success.svg'

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [linkError, setLinkError] = useState(true);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                default:
            }
        }
    }, [value, validations]);

    useEffect(() => {
        if (isEmpty) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }

    }, [isEmpty])

    return {
        isEmpty
    }
}

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid
    }
}

const Form = () => {
    const name = useInput('', { isEmpty: true });
    const phone = useInput('', { isEmpty: true });
    const children = useInput('', { isEmpty: true });
    const child_supporter = useInput('', { isEmpty: true });
    const animals = useInput('ні', { isEmpty: true });
    const international_pass = useInput('так', { isEmpty: true });
    const comment = useInput('', { isEmpty: true });
    const [letterSucces, setLetterSucces] = useState('');
    const [textErr, setTextErr] = useState('');
    const [city, setCity] = useState('');
    const [cityShow, setCityShow] = useState(false);
    const cities = ['Харківська область', 'Сумська область', 'Запорізька область',
        'Дніпропетровська область', 'Донецька область', 'Луганська область'];

    const data = {
        from_name: name.value,
        phone: phone.value,
        city: city,
        children: children.value,
        child_supporter: child_supporter.value,
        animals: animals.value,
        international_pass: international_pass.value,
        message: comment.value,
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        send(
            'service_ey8qxzu',
            'template_2tc5kbs',
            data,
            '_etWiG8SHb-9w-1DV'
        )
            .then((response) => {
                setLetterSucces(true);
            })
            .catch((err) => {
                setLetterSucces(false);
                setTextErr(err);
            });
    }

    const renderDropdownItems = (item, i) => {
        return (
            <div className='dropdown_city--item' onClick={() => setCity(item)} key={`dropdown_city--item-${i}`}>
                <p className='dropdown_city--item_p'>{item}</p>
            </div>
        )
    }

    const toggleDropdown = () => {
        setCityShow(!cityShow);
    }

    return (
        <>
            {!(letterSucces === true || letterSucces === false) &&
                <>
                    <h2 className='popup_title'>Потребуєте евакуації?</h2>
                    <p className='popup_desc'>Заповніть форму і ми звʼяжемось з вами у найближчий час.</p>
                </>}
            {!(letterSucces === true || letterSucces === false) && <form className='popup_form' onSubmit={onSubmit}>
                <div className='popup_inputs_block'>
                    <label>
                        <input placeholder='Ваше ім’я*' onChange={e => name.onChange(e)} onBlur={e => name.onBlur(e)} className={`popup_input ${(name.isDirty && name.isEmpty) && 'error'}`} value={name.value} type="text" name="from_name" />
                    </label>
                    <label>
                        <input placeholder='Телефон*' onChange={e => phone.onChange(e)} onBlur={e => phone.onBlur(e)} className={`popup_input ${(phone.isDirty && phone.isEmpty) && 'error'}`} value={phone.value} type="text" name="phone" />
                    </label>
                    <div className='dropdown_city_block'>
                        <div className='dropdown_city--header' onClick={toggleDropdown}>
                            <p className='dropdown_city--header_title'>{city === '' ? 'Оберіть регіон евакуації*' : city}</p>
                            <svg className={`${cityShow === true && 'active'}`} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 15L14.7735 5L3.2265 5L9 15Z" fill="#6C7187" />
                            </svg>
                        </div>
                        {cityShow === true && <div className='dropdown_city--body' onClick={toggleDropdown}>
                            {cities.map((item, i) => renderDropdownItems(item, i))}
                        </div>}
                    </div>
                    <label>
                        <input placeholder='Скільки з вами дітей?*' onChange={e => children.onChange(e)} onBlur={e => children.onBlur(e)} className={`popup_input ${(children.isDirty && children.isEmpty) && 'error'}`} value={children.value} type="text" name="children" />
                    </label>
                    <label>
                        <input placeholder='Хто супроводжує дітей?*' onChange={e => child_supporter.onChange(e)} onBlur={e => child_supporter.onBlur(e)} className={`popup_input ${(child_supporter.isDirty && child_supporter.isEmpty) && 'error'}`} value={child_supporter.value} type="text" name="child_supporter" />
                    </label>
                    <div className='popup_radio_block' onChange={e => animals.onChange(e)} value={animals.value}>
                        <p className='popup_radio_block--title'>Чи є з вами тварини?</p>
                        <div className='popup_radio_block--inner'>
                            <label className="form-check popup_radio_block--label">
                                <input
                                    type="radio"
                                    name="animals"
                                    value="так"
                                    className="popup_radio"
                                />
                                Так
                            </label>
                            <label className="form-check popup_radio_block--label">
                                <input
                                    type="radio"
                                    name="animals"
                                    value="ні"
                                    className="popup_radio"
                                />
                                Ні
                            </label>
                        </div>
                    </div>
                </div>
                <div className='popup_radio_block' onChange={e => international_pass.onChange(e)} value={international_pass.value}>
                    <p className='popup_radio_block--title'>Чи є закордонний паспорт?</p>
                    <div className='popup_radio_block--inner'>
                        <label className="form-check popup_radio_block--label">
                            <input
                                type="radio"
                                name="pass"
                                value="так"
                                className="popup_radio"
                            />
                            Так
                        </label>
                        <label className="form-check popup_radio_block--label">
                            <input
                                type="radio"
                                name="pass"
                                value="ні"
                                className="popup_radio"
                            />
                            Ні
                        </label>
                    </div>
                </div>
                <label>
                    <input placeholder='Комментар до заявки' onChange={e => comment.onChange(e)} onBlur={e => comment.onBlur(e)} className='popup_input' value={comment.value} type="text" name="comment" />
                </label>
                <input disabled={name.isEmpty || phone.isEmpty || children.isEmpty || child_supporter.isEmpty} className='form_btn popup_btn' type="submit" value="Відправити" />
            </form>}
            {
                letterSucces === true && <div className='form_message'>
                    <p className='form_message--title'>Ваша заява успішно подана!</p>
                    <p className='form_message--desc'>Наші волонтери зв'яжуться з вами найближчий час!</p>
                    <svg className='form_message--slogan' width="198" height="155" viewBox="0 0 198 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.2376 53.8255H34.7592V57.427H20.2192V37.3012H34.4948V40.9026H24.2376V45.3515H32.1156V48.847H24.2376V53.8255Z" fill="#6BB378" />
                        <path d="M51.9428 37.3012H56.2254L48.8762 57.427H43.7475L36.3982 37.3012H40.7338L46.3383 53.5077L51.9428 37.3012Z" fill="#6BB378" />
                        <path d="M71.9815 57.427L70.3953 53.19H61.3012L59.715 57.427H55.4852L63.4161 37.3012H68.3861L76.317 57.427H71.9815ZM62.623 49.6415H69.0735L65.8482 40.9026L62.623 49.6415Z" fill="#6BB378" />
                        <path d="M87.1559 57.6918C84.2303 57.6918 81.8862 56.7561 80.1238 54.8848C78.3614 52.9781 77.4802 50.4712 77.4802 47.3641C77.4802 45.3515 77.8856 43.5684 78.6963 42.0149C79.507 40.426 80.6349 39.2078 82.0801 38.3604C83.5605 37.4777 85.2525 37.0364 87.1559 37.0364C89.306 37.0364 91.1389 37.5483 92.6546 38.5723C94.1703 39.5962 95.2101 41.0439 95.7741 42.9152L91.9673 44.2922C91.1918 41.75 89.5704 40.4789 87.103 40.4789C85.4463 40.4789 84.1069 41.0968 83.0847 42.3326C82.0977 43.5684 81.6043 45.2456 81.6043 47.3641C81.6043 49.4826 82.0977 51.1597 83.0847 52.3955C84.0716 53.6313 85.4111 54.2492 87.103 54.2492C89.5704 54.2492 91.1918 52.9781 91.9673 50.4359L95.7741 51.8129C95.1749 53.649 94.1174 55.0966 92.6017 56.1559C91.0861 57.1798 89.2708 57.6918 87.1559 57.6918Z" fill="#6BB378" />
                        <path d="M107.988 57.6918C105.309 57.6918 103.176 56.9503 101.59 55.4674C100.004 53.9491 99.2108 51.9189 99.2108 49.3767V37.3012H103.282V49.2707C103.282 50.789 103.687 51.9718 104.498 52.8192C105.344 53.6666 106.507 54.0903 107.988 54.0903C109.503 54.0903 110.667 53.6666 111.477 52.8192C112.288 51.9718 112.693 50.789 112.693 49.2707V37.3012H116.764V49.3767C116.764 51.9189 115.971 53.9491 114.385 55.4674C112.834 56.9503 110.702 57.6918 107.988 57.6918Z" fill="#6BB378" />
                        <path d="M135.111 57.427L133.525 53.19H124.431L122.845 57.427H118.615L126.546 37.3012H131.516L139.447 57.427H135.111ZM125.753 49.6415H132.203L128.978 40.9026L125.753 49.6415Z" fill="#6BB378" />
                        <path d="M156.102 37.3012V41.0086H149.175V57.427H145.104V41.0086H138.178V37.3012H156.102Z" fill="#6BB378" />
                        <path d="M163.451 53.8255H173.973V57.427H159.433V37.3012H173.708V40.9026H163.451V45.3515H171.329V48.847H163.451V53.8255Z" fill="#6BB378" />
                        <path d="M153.775 72.1657V92.2915H149.81V77.3031L144.258 92.2915H140.028L134.424 77.1442V92.2915H130.458V72.1657H136.539L142.143 87.4189L147.801 72.1657H153.775Z" fill="#6BB378" />
                        <path d="M163.451 88.69H173.973V92.2915H159.433V72.1657H173.708V75.7672H163.451V80.216H171.329V83.7115H163.451V88.69Z" fill="#6BB378" />
                        <g filter="url(#filter0_bi_182_951)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M161.795 121.262H20.5796C10.3422 121.262 2.9386 114.905 2.9386 108.038V16.1673C2.9386 9.30007 10.3422 2.94334 20.5796 2.94334H177.216C187.453 2.94334 194.857 9.30007 194.857 16.1673V147.039C194.857 148.704 193.583 150.298 191.23 151.125C189.003 151.907 186.308 151.776 184.212 150.482L161.795 121.262ZM182.878 151.931C188.304 155.609 196.795 152.624 196.795 147.039V16.1673C196.795 7.79061 188.029 1 177.216 1H20.5796C9.76605 1 1.00003 7.79061 1.00003 16.1673V108.038C1.00003 116.414 9.76605 123.205 20.5796 123.205H160.84L182.878 151.931Z" fill="#6BB378" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M161.795 121.262H20.5796C10.3422 121.262 2.9386 114.905 2.9386 108.038V16.1673C2.9386 9.30007 10.3422 2.94334 20.5796 2.94334H177.216C187.453 2.94334 194.857 9.30007 194.857 16.1673V147.039C194.857 148.704 193.583 150.298 191.23 151.125C189.003 151.907 186.308 151.776 184.212 150.482L161.795 121.262ZM182.878 151.931C188.304 155.609 196.795 152.624 196.795 147.039V16.1673C196.795 7.79061 188.029 1 177.216 1H20.5796C9.76605 1 1.00003 7.79061 1.00003 16.1673V108.038C1.00003 116.414 9.76605 123.205 20.5796 123.205H160.84L182.878 151.931Z" stroke="#6BB378" strokeWidth="2" />
                        </g>
                        <defs>
                            <filter id="filter0_bi_182_951" x="-4" y="-4" width="205.795" height="162.552" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_182_951" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_182_951" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dx="0.2" dy="0.1" />
                                <feGaussianBlur stdDeviation="0.5" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
                                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_182_951" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            }
            {
                letterSucces === false && <div className='form_message'>
                    <img className='form_message--img' src={fail} alt="" />
                    <p>Не вдалося доставити надісланий вами лист. Помилка: {textErr}</p>
                </div>
            }
        </>
    );
};

export default Form;